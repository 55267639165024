import { Link } from "gatsby"
import React from "react"
import he from 'he';
import moment from 'moment';
import 'moment/locale/nl';



// alle nieuws online zetten.
// data date correct maken

const NewsBlock = ({article}) => {
    const date = moment(article.Date, 'YYYY-MM-DD');
    const intro = he.decode((article.Content)).replace(/(<([^>]+)>)/gi, "")

    return <div className="relative pr-10 pl-10 f-container f-column">
        <div className="h-150 mb-10 full-width center-bg" style={{backgroundImage: `url(${process.env.IMAGE_BASE_URL}${article.Image.url})`}} />
        <h4 className="bold t-16">
            {article.Title}
        </h4>
        <div className={'mb-10'}>{ date.locale('nl').format('LL')}</div>
        <div className={'lc-5'} dangerouslySetInnerHTML={{ __html: intro }}/>
        {<Link to={`/nieuws/${article.Slug}`} className={`mt-30 blue-button f-align-start`}>Lees meer
        </Link>}
    </div>

}

export default NewsBlock
